import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DevicesResponse, LoginResponse, UpdateAvatar,
    LogoutResponse, UpdateProfileBody, UpdateProfileResponse,
     GetSettingAPIResponse as GetSettingAPIResponse,
     PostSettingAPIResponse, PasswordResponse} from '../models/login';

import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends Api {
    public login(email: string, password: string): Observable<LoginResponse> {
        return this.withIdToken().post<LoginResponse>('/user/auth/signin', {
            email,
            password,
        })
    }

    public registerDevices(device_name: string, email_address: string){
        return this.withIdToken().post<DevicesResponse>('/devices', {
            device_name,
            email_address,
            "phone_number": "",
            "push_token": "",
            "subscribed": "",
            "last_activate": "",
            "first_session": "",
            "sessions": "",
            "app_version": "",
            "country": "",
            "ip_address": "",
            "sdk_version": "",
            "lat": "",
            "long": "",
            "usage_duration": "",
            "language_code": "",
            "external_user_id": "",
            "segments": "",
            "tags": ""
        })
    }

    public putDevices(device_name: string, email_address: string, device_id: string){
        return this.withIdToken().put<DevicesResponse>('/devices/' + device_id, {
            device_name,
            email_address,
            "phone_number": "",
            "push_token": "",
            "subscribed": "",
            "last_activate": "",
            "first_session": "",
            "sessions": "",
            "app_version": "",
            "country": "",
            "ip_address": "",
            "sdk_version": "",
            "lat": "",
            "long": "",
            "usage_duration": "",
            "language_code": "",
            "external_user_id": "",
            "segments": "",
            "tags": ""
        })
    }

    public forgottenPassword(email: string){
        return this.withIdToken().get<LoginResponse>('/user/auth/recovery?email=' + email)
    }

    public passwordRecovery(email: string, activate_code: number, new_password: string) {
        return this.withIdToken().post<PasswordResponse>('/user/auth/recovery', {
            email,
            activate_code,
            new_password
        })
    }

    public profile(){
        return this.withIdToken().get<LoginResponse>('/user/profile')
    }

    public updateProfile(user: UpdateProfileBody){
        return this.withIdToken().put<UpdateProfileResponse>('/user/profile', user)
    }
    public logoutFromApp() {
        let device_id = Api.getDeviceId()
        let res = this.withIdToken().delete<LogoutResponse>('/devices/' + device_id)
        this.logout()
        return res
    }
    getUpdateProfilePictureLink() {
        return this.withIdToken().get<UpdateAvatar>('/upload/avatar', {
            file_name: 'image',
        })
    }

    updateProfileAvatar(avatarUrl: string) {
        return this.withIdToken().put<UpdateProfileResponse>('/user/profile', {
            avatar: avatarUrl,
        })
    }

    uploadProfileImage(url: string, file: File) {
        return this.withIdToken().http.put(url, file, {
            headers: {
                'x-amz-acl': 'public-read'
            }
        })
    }

    public postSetting(posture_type: string): Observable<PostSettingAPIResponse> {
        return this.withIdToken().post<PostSettingAPIResponse>('/settings', {
            posture_type,
        })
    }

    public getSettings(){
        return this.withIdToken().get<GetSettingAPIResponse>('/settings')
    }

    public getBillingPortalUrl() {
        return this.withIdToken().get<any>('/user/billing-portal')
    }
}
