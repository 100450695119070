import { Capacitor } from '@capacitor/core';
import { UserService } from '../services/user.service';
import { AppInjector } from '../app.module';
import { Api } from '../services/api.service';

export class BasePage {
  get mobile(): boolean {
    return Capacitor.isNativePlatform();
  }

  get isIPhone(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  get isIframe () {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
  }

  get isLoggedIn () {
    return Api.getToken() !== null;
  }

  public refresh_token() {
    AppInjector.get(UserService).refresh_token().subscribe(response => {
      localStorage.setItem('hb-token', response.data.id_token);
      localStorage.setItem('hb-access-token', response.data.access_token);
    });
  }
}
