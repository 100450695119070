
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class Api {
    protected host: string = 'https://app.api.herzbegleiter.de/api/v1'
    private static token?: string = null;
    private static access?: string = null; // this will be accesible everywhere.
    private static refresh?: string = null;
    private static deviceId: string = null;
    private static user_id: string = null;
    private static user_email: string = null;

    private tokenToUse: string; // tell this class which token to use

    constructor(protected http: HttpClient) {
        if (Api.token === null && localStorage.getItem('hb-token')) {
            Api.token = localStorage.getItem('hb-token');
        }

        if (Api.access === null && localStorage.getItem('hb-access-token')) {
            Api.access = localStorage.getItem('hb-access-token');
        }

        if (Api.deviceId === null && localStorage.getItem('device-id')) {
            Api.deviceId = localStorage.getItem('device-id');
        }

        if (Api.refresh === null && localStorage.getItem('hb-refresh-token')) {
            Api.refresh = localStorage.getItem('hb-refresh-token');
        }

        if (Api.user_id === null && localStorage.getItem('user-id')) {
            Api.deviceId = localStorage.getItem('user-id');
        }
    }

    public static getId(): string {
        return Api.user_id || localStorage.getItem('user-id');
    }
    public static setId(user_id: string) {
        Api.user_id = user_id;
        localStorage.setItem('user-id', user_id)
    }

    public static getToken(): string {
        return Api.token || localStorage.getItem('hb-token');
    }

    /**
     * Replace the old id token both in Api's token property
     * and client's localStorage.
     */
    public static setToken(token: string): void {
        this.token = token
        localStorage.setItem('hb-token', token)
    }



    public static getAccess(): string {
        return Api.access || localStorage.getItem('hb-access-token');
    }

    /**
     * Replace the old access token both in Api's access property
     * and client's localStorage.
     */
    public static setAccess(access: string): void {
        this.access = access;
        localStorage.setItem('hb-access-token', access);
    }

    public static getRefresh(): string {
        return Api.refresh || localStorage.getItem('hb-refresh-token');
    }

    /**
     * Replace the old refresh token both in Api's access property
     * and client's localStorage.
     */
    public static setRefresh(refresh: string): void {
        this.refresh = refresh;
        localStorage.setItem('hb-refresh-token', refresh);
    }

    public static getDeviceId(): string {
        return Api.deviceId || localStorage.getItem('device-id');
    }

    public static setDeviceId(deviceId: string): void {
        this.deviceId = deviceId
        localStorage.setItem('device-id', deviceId)
    }

    public static getLoggedInEmail(email : string): boolean {
        return (Api.user_email === email) || localStorage.getItem('screen-' + email) !== null;
    }

    public static setLoggedInEmail(email: string): void {
        this.user_email = email
        localStorage.setItem('screen-' + email, "")
    }

    public logout() {
        Api.token = null;
        Api.deviceId = null;
        Api.access = null;
        Api.refresh = null;
        Api.user_id = null;
        Api.user_email = null;
        localStorage.removeItem('hb-last-refresh');
        localStorage.removeItem('hb-token');
        localStorage.removeItem('device-id');
        localStorage.removeItem('hb-access-token');
        localStorage.removeItem('hb-refresh-token');
        localStorage.removeItem('user-id')
    }

    protected withAccessToken() {
        this.tokenToUse = 'access';
        return this;
    }

    protected withIdToken() {
        this.tokenToUse = 'id';
        return this;
    }

    protected get<T>(endpoint: string, params?: HttpParams|{
        [param: string]: string | number | boolean | readonly (string | number | boolean)[];
    }) {
        return this.http.get<T>(this.host + endpoint, { params, headers: this.headers() });
    }

    protected post<T>(endpoint: string, body: any) {
        return this.pipes(
            this.http.post<T>(this.host + endpoint, body, { headers: this.headers() })
                .pipe(
                    shareReplay(),
                )
        );
    }

    protected put<T>(endpoint: string, body: any) {
        return this.http.put<T>(this.host + endpoint, body, { headers: this.headers() });
    }

    protected delete<T>(endpoint: string) {
        return this.http.delete<T>(this.host + endpoint, { headers: this.headers() });
    }

    protected patch<T>(endpoint: string, body: any) {
        return this.http.patch<T>(this.host + endpoint, body, { headers: this.headers() });
    }

    private headers(): { [key: string]: string } {
        const headers = {
            'Accept': 'application/json, text/plain, */*'
        };

        // Set authorization as a header parameter
        switch (this.tokenToUse) {
            case 'id':
                headers['Authorization'] = `${Api.token}`;
                break;
            case 'access':
                headers['Authorization'] = `${Api.access}`;
                break;
            case 'refresh':
                headers['Authorization'] = `${Api.refresh}`;
        }

        return headers;
    }

    private pipes<T>(observable: Observable<T>): Observable<T> {
        const pipes = [
            this.authenticateIfNeeded,
        ];

        for (const pipe of pipes) {
            pipe(observable);
        }

        return observable;
    }

    private authenticateIfNeeded<T>(observable: Observable<T>): void {
        observable.subscribe(response => {
            if ((response as any).data?.token) {
                Api.token = (response as any).data.token;
                localStorage.setItem('hb-token', Api.token);
            }
        });
    }
}