import { TabsGuard } from './guards/tabs.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { NotSubscribedGuard } from './guards/not-subscribed.guard';
import { SubscribedGuard } from './guards/subscribed.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sign-in'
  },
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then( m => m.LoginPageModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./views/registration/registration.module').then( m => m.RegistrationPageModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'zur-app',
    loadChildren: () => import('./views/registation-confirmed/registation-confirmed.module').then( m => m.RegistationConfirmedPageModule),
    canActivate: [GuestGuard]
  },
  {
    path:'sign-in',
    loadChildren: () => import('./views/login/login.module').then( m => m.LoginPageModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./views/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule),
    canActivate: [GuestGuard]
  },
  {
    path: 'recovery',
    loadChildren: () => import('./views/password-recovery/password-recovery.module').then(m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'newsfeed/intro',
    loadChildren: () => import('./views/newsfeed-intro/newsfeed-intro.module').then( m => m.NewsfeedIntroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./views/tabs/tabs.module').then( m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-menu',
    loadChildren: () => import('./views/profile-menu/profile-menu.module').then( m => m.ProfileMenuPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./views/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'update-bio',
    loadChildren: () => import('./views/add-bio/add-bio.module').then( m => m.AddBioPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  /*
  {
     path: 'questions',
     loadChildren: () => import('./views/questions/questions.module').then( m => m.QuestionsPageModule),
     canActivate: [AuthGuard, TabsGuard]
   },
   */
  {
    path: 'answers',
    loadChildren: () => import('./views/answers/answers.module').then( m => m.AnswersPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'mobility/intro',
    loadChildren: () => import('./views/mobility-intro/mobility-intro.module').then( m => m.MobilityIntroPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'mobility/posture',
    loadChildren: () => import('./views/mobility-posture/mobility-posture.module').then( m => m.MobilityPosturePageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'mobility/bibliothek',
    loadChildren: () => import('./views/mobility-video-bibliothek/mobility-video-bibliothek.module').then(m => m.MobilityVideoBibliothekPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'service-support',
    loadChildren: () => import('./views/service-support/service-support.module').then( m => m.ServiceSupportPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'profile/change-password',
    loadChildren: () => import('./views/profile-change-password/profile-change-password.module').then( m => m.ProfileChangePasswordPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'mobility',
    loadChildren: () => import('./views/mobility/mobility.module').then( m => m.MobilityPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'mobility/trainer-detail/:id',
    loadChildren: () => import('./views/trainer-detail/trainer-detail.module').then( m => m.TrainerDetailPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'mobility/saved',
    loadChildren: () => import('./views/mobility-favorites/mobility-favorites.module').then( m => m.MobilityFavoritesPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard],
  },
  {
    path: 'mobility/history',
    loadChildren: () => import('./views/mobility-favorites/mobility-favorites.module').then( m => m.MobilityFavoritesPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard],
  },
  {
    path: 'mobility/alle-trainer',
    loadChildren: () => import('./views/mobility-alle-trainer/mobility-alle-trainer.module').then(m => m.MobilityAlleTrainerPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard],
  },
  {
    path: 'mobility-video-bibliothek',
    loadChildren: () => import('./views/mobility-video-bibliothek/mobility-video-bibliothek.module').then(m => m.MobilityVideoBibliothekPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'mobility/search-page',
    loadChildren: () => import('./views/search-page/search-page.module').then(m => m.SearchPagePageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'activity',
    loadChildren: () => import('./views/activity/activity.module').then(m => m.ActivityPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'newsfeed',
    loadChildren: () => import('./views/newsfeed/newsfeed.module').then( m => m.NewsfeedPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'newsfeed/favorite',
    loadChildren: () => import('./views/newsfeed-favorite/newsfeed-favorite.module').then( m => m.NewsfeedFavoritePageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'mobility/hashtag/:tag',
    loadChildren: () => import('./views/mobility-videos-from-tags/mobility-videos-from-tags.module').then(m => m.MobilityVideosFromTagsPageModule),
    canActivate: [AuthGuard, TabsGuard, SubscribedGuard]
  },
  {
    path: 'mobility/payment-intro',
    loadChildren: () => import('./views/mobility-payment-intro/mobility-payment-intro.module').then(m => m.MobilityPaymentIntroPageModule),
    canActivate: [AuthGuard, TabsGuard, NotSubscribedGuard],
  },
  {
    path: 'mobility/enter-key-code',
    loadChildren: () => import('./views/mobility-enter-key-code/mobility-enter-key-code.module').then( m => m.MobilityEnterKeyCodePageModule),
    canActivate: [AuthGuard, TabsGuard, NotSubscribedGuard],
  },
  {
    path: 'mobility/enter-coupon-code',
    loadChildren: () => import('./views/mobility-enter-coupon-code/mobility-enter-coupon-code.module').then( m => m.MobilityEnterCouponCodePageModule),
    canActivate: [AuthGuard, TabsGuard, NotSubscribedGuard],
  },
  {
    path: 'profile/documents',
    loadChildren: () => import('./views/profile-documents/profile-documents.module').then(m => m.ProfileDocumentsPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'mobility/add-insurance',
    loadChildren: () => import('./views/mobility-add-insurance/mobility-add-insurance.module').then(m => m.MobilityAddInsurancePageModule),
    canActivate: [AuthGuard, TabsGuard, NotSubscribedGuard]
  },
  {
    path: 'consulting',
    loadChildren: () => import('./views/beratung-intro/beratung-intro.module').then( m => m.BeratungIntroPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'embed/newsfeed',
    loadChildren: () => import('./views/embed/newsfeed/newsfeed.module').then( m => m.NewsfeedPageModule)
  },
  {
    path: 'init-profile',
    loadChildren: () => import('./views/init-profile/init-profile.module').then( m => m.InitProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'consulting/:type/view-appointments',
    loadChildren: () => import('./views/beratung-main-menu/beratung-main-menu.module').then( m => m.BeratungMainMenuPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'consulting/:type/view-appointments/:status',
    loadChildren: () => import('./views/beratung-view-appointment/beratung-view-appointment.module').then( m => m.BeratungViewAppointmentPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./views/verify-registration/verify-registration.module').then( m => m.VerifyRegistrationPageModule)
  },
  {
    path: 'consulting/view-appointments/:id',
    loadChildren: () => import('./views/beratung-appointment-details/beratung-appointment-details.module').then( m => m.BeratungAppointmentDetailsPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'consulting/intro-video',
    loadChildren: () => import('./views/beratung-intro-video/beratung-intro-video.module').then( m => m.BeratungIntroVideoPageModule)
  },
  {
    path: 'consulting/book-an-appointment/nurse',
    loadChildren: () => import('./views/book-an-appointment/book-an-appointment.module').then( m => m.BookAnAppointmentPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'consulting/feedback',
    loadChildren: () => import('./views/beratung-feedback/beratung-feedback.module').then( m => m.BeratungFeedbackPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'consulting/report',
    loadChildren: () => import('./views/beratung-nurse-prescription/beratung-nurse-prescription.module').then( m => m.BeratungNursePrescriptionPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  {
    path: 'consulting/book-an-appointment/survey/:diseaseId',
    loadChildren: () => import('./views/book-an-appointment-survey/book-an-appointment-survey.module').then( m => m.BookAnAppointmentSurveyPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
  /*{
    path: 'consulting/book-an-appointment/assistant',
    loadChildren: () => import('./views/book-aservice-survey/book-aservice-survey.module').then( m => m.BookAServiceSurveyPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },*/
  // {
  //   path: 'video-call',
  //   loadChildren: () => import('./views/video-call/video-call.module').then( m => m.VideoCallPageModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'delete-edit-appointment',
    loadChildren: () => import('./views/delete-edit-appointment/delete-edit-appointment.module').then( m => m.DeleteEditAppointmentPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mobility/mobility-watch-video/mobility-video-document',
    loadChildren: () => import('./views/mobility/mobility-watch-video/mobility-video-document/mobility-video-document.module').then( m => m.MobilityVideoDocumentPageModule),
    canActivate: [AuthGuard, SubscribedGuard]
  },
  {
    path: 'beratung-watch-recording',
    loadChildren: () => import('./views/beratung-watch-recording/beratung-watch-recording.module').then( m => m.BeratungWatchRecordingPageModule),
    canActivate: [AuthGuard, TabsGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'top' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
