import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators'
import { UserRepository } from '../repositories/user.repository';
import { SubscriptionService } from '../services/subscription.service';
import { MobilityService } from '../services/mobility.service';

@Injectable({
  providedIn: 'root'
})
export class SubscribedGuard implements CanActivate {
  constructor(
    private subscriptionService: SubscriptionService,
    private mobilityService: MobilityService,
    private router: Router,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.mobilityService.checkCouponCode().pipe(
            map(res => {
                const user = UserRepository.getInstance().user();
                if (!user || (!this.subscriptionService.subscription(user) && res.message.status !== 'success')) {
                    if(localStorage.getItem('MobilityIntro-' + user.email) === null){
                        return this.router.parseUrl('/mobility/intro');
                    } else {
                        return this.router.parseUrl('/mobility/payment-intro');
                    }
                }
                return true;
            })
        );
}

}
