import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserRepository } from '../repositories/user.repository';
import { SubscriptionService } from '../services/subscription.service';

@Injectable({
  providedIn: 'root'
})
export class NotSubscribedGuard implements CanActivate {
  constructor(
    private subscriptionService: SubscriptionService,
    private router: Router,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user = UserRepository.getInstance().user()
      if (user && this.subscriptionService.subscription(user)) {
        if(localStorage.getItem('MobilityIntro-' + user.email) === null){
          this.router.navigate(['/mobility/intro']);
        } else {
          this.router.navigate(['/mobility']);
        }
        return false;
      }

      return true;
  }

}
