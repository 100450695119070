import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserModel } from '../models/login';
import { RefreshTokenResponse } from '../models/user';
import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends Api {

  public refresh_token(): Observable<RefreshTokenResponse> {
    return this.withAccessToken().post<RefreshTokenResponse>("/user/auth/refresh-token", {
      "refresh_token": localStorage.getItem("hb-refresh-token")
    });
  }

}
