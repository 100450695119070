import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/login';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
    subscription(user: UserModel) {
        if (user.dipa_request && user.dipa_request.status.endsWith('received')) {
            return true;
        }

        return this.stripeSubscription(user);
    }

    stripeSubscription(user: UserModel) {
        if (user.stripe_payment.length != 0) {
            return user.stripe_payment.some((item: any) => {
                const {
                    product_id: productId,
                    payment_intent_status: paymentIntentStatus,
                    subscription_status: subscriptionStatus,
                } = item;
                const subscriptionStatusArr = ['canceled', '', 'unpaid', 'past_due'];
                const paymentIntentStatusArr = ['', 'canceled', 'requires_payment_method'];
                return (
                    productId === environment.stripeProductId &&
                    !subscriptionStatusArr.includes(subscriptionStatus) &&
                    !paymentIntentStatusArr.includes(paymentIntentStatus)
                );
            });
        }

        return false
    }
}
