import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Stripe } from '@capacitor-community/stripe';
import { environment } from 'src/environments/environment';
import { ViewportScroller } from '@angular/common';
import { TextZoom } from '@capacitor/text-zoom';
import { Capacitor } from '@capacitor/core';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(private route: ActivatedRoute, private router: Router, private viewPortScroller: ViewportScroller) {}

  async ngOnInit() {
    await Stripe.initialize({
      publishableKey: environment.stripePublicKey,
    });

    if (Capacitor.isNativePlatform()) {
      await TextZoom.set({
        value: 1.0,
      });
    }
  }
}
