import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { Api } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {
  constructor(
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (Api.getToken() !== null) {
      if (Api.getToken().toLowerCase() === "undefined") {
        return true;
      } else {
        Capacitor.isNativePlatform() ? this.router.navigate(['/tabs/newsfeed']) : this.router.navigate(['/newsfeed']);
        return false;
      }
    } else {
      return true;
    }
  }

}
