import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { RouteReuseStrategy } from '@angular/router'
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx'


import { IonicModule, IonicRouteStrategy } from '@ionic/angular'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { Injector } from '@angular/core'

export let AppInjector: Injector

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			mode: 'md',
		}),
		AppRoutingModule,
		HttpClientModule,
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		[InAppBrowser],
	],
	bootstrap: [AppComponent],

})
export class AppModule {
	constructor(private injector: Injector) {
		AppInjector = this.injector
	}
}
