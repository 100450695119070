import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as dayjs from 'dayjs';
import 'dayjs/locale/de'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@stripe-elements/stripe-elements/loader';


const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.locale('de');
dayjs.extend(customParseFormat);
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin
dayjs.extend(utc)
dayjs.extend(timezone)

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => defineCustomElements(window))
  .catch(err => console.log(err));
