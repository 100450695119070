import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryAPIResponde, LibraryAPIResponde, TrainersAPIResponde, TrainerAPIResponse, VideosAPIResponse, VideoAPIResponse, FollowAPIResponse, SearchHistoryAPIResponse, searchHistoryRecord, videosFromTagApiResponse, CouponAPIResponse } from '../models/mobility';
import { Api } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class MobilityService extends Api {

  public getCategories(posture?: string) {
    return this.withIdToken().get<CategoryAPIResponde>('/mobility/categories', JSON.parse(JSON.stringify({
      posture_type: posture
    })));
  }

  public getLibraries() {
    return this.withIdToken().get<LibraryAPIResponde>('/mobility/libraries')
  }

  public getTrainers(is_followed, page_size) {
    return this.withIdToken().get<TrainersAPIResponde>(`/mobility/trainers`, JSON.parse(JSON.stringify({
      followed: is_followed,
      page_size
    })));
  }

  public getTrainer(id: string) {
    return this.withIdToken().get<TrainerAPIResponse>('/mobility/trainers/' + id)
  }

  public getTrainerVideo(id: string, page: number = 1, pageSize: number = 24) {
    return this.withIdToken().get<VideoAPIResponse>('/mobility/trainers/' + id + '/videos', JSON.parse(JSON.stringify({
      page,
      page_size: pageSize,
    })));
  }

  public getVideos(posture?: string, categoryId?: string, searchValue?: string) {
    if (categoryId == null && searchValue == null) {
      return this.withIdToken().get<VideosAPIResponse>('/mobility/videos', JSON.parse(JSON.stringify({
        value: '',
        page: 1,
        page_size: 24,
        posture_type: posture
      })));
    } else if (searchValue == null) {
      return this.withIdToken().get<VideosAPIResponse>('/mobility/videos', JSON.parse(JSON.stringify({
        value: '',
        page: 1,
        page_size: 24,
        category_id: categoryId,
        posture_type: posture,
      })));
    } else if (categoryId == null) {
      return this.withIdToken().get<VideosAPIResponse>('/mobility/videos', JSON.parse(JSON.stringify({
        value: searchValue,
        page: 1,
        page_size: 24,
        posture_type: posture
      })));
    }
  }

  public getMoreVideos(page: number, posture?: string, categoryId?: string, searchValue?: string) {
    if (categoryId == null && searchValue == null) {
      return this.withIdToken().get<VideosAPIResponse>('/mobility/videos', JSON.parse(JSON.stringify({
        value: '',
        page,
        page_size: 12,
        posture_type: posture
      })));
    } else if (searchValue == null) {
      return this.withIdToken().get<VideosAPIResponse>('/mobility/videos', JSON.parse(JSON.stringify({
        value: '',
        page,
        page_size: 12,
        category_id: categoryId,
        posture_type: posture
      })));
    } else if (categoryId == null) {
      return this.withIdToken().get<VideosAPIResponse>('/mobility/videos', JSON.parse(JSON.stringify({
        value: searchValue,
        page,
        page_size: 24,
        posture_type: posture,
      })));
    }
  }

  public async getFavoriteVideos(is_saved: number, value: string, page_num: number, page_size: number) {
    let items = []
    const resp = await this.withIdToken().get<VideosAPIResponse>(`/mobility/videos`, JSON.parse(JSON.stringify({
      is_save: is_saved,
      value,
      page: page_num,
      page_size
    }))).toPromise();
    resp.data.videos.forEach(item => {
      if (!items.some(i => i.id === item.id)) {
        items.push(item)
      }
    })
    return items

  }

  public setFavoriteVideos(id: string): Observable<VideosAPIResponse> {
    return this.withIdToken().post<VideosAPIResponse>(`/mobility/videos/${id}/save`, '');
  }
  public deleteFavoriteVideos(id: string): Observable<VideosAPIResponse> {
    return this.withIdToken().put<VideosAPIResponse>(`/mobility/videos/${id}/unsave`, '');
  }

  public deleteAllFavoriteVideos() {
    return this.withIdToken().delete<VideosAPIResponse>(`/mobility/videos/unsave`);
  }

  public setFollowTrainer(id: string) {
    return this.withIdToken().post<FollowAPIResponse>(`/mobility/trainers/${id}/follow`, '');
  }
  public deleteFollowTrainer(id: string) {
    return this.withIdToken().delete<FollowAPIResponse>(`/mobility/trainers/${id}/unfollow`);
  }

  public getSearchHistory(keyword: string) {
    return this.withIdToken().get<SearchHistoryAPIResponse>(`/mobility/videos/history/search`, JSON.parse(JSON.stringify({ keyword })));
  }

  public deleteSearchHistoryRecord(id: string) {
    return this.withIdToken().delete<SearchHistoryAPIResponse>(`/mobility/videos/history/search/${id}`);
  }

  public async getVideoHistory(search_name: string, page_num: number, page_size: number) {
    let items = []
    const resp = await this.withIdToken().get<VideosAPIResponse>(`/mobility/videos/history/watch`, JSON.parse(JSON.stringify({
      search_name,
      page: page_num,
      page_size
    }))).toPromise();
    resp.data.videos.forEach(item => {
      if (!items.some(i => i.id === item.id)) {
        items.push(item)
      }
    })
    return items
  }

  public deleteSearchHistory() {
    return this.withIdToken().delete<SearchHistoryAPIResponse>(`/mobility/videos/history/search`);
  }

  public deleteVideoHistory() {
    return this.withIdToken().delete<VideosAPIResponse>(`/mobility/videos/history/watch`);
  }

  public getHashtagVideos(tag: string, page: number, pageSize: number) {
    return this.withIdToken().get<videosFromTagApiResponse>('/mobility/hashtags/' + tag, {
      page,
      page_size: pageSize,
    });
  }

  public checkCouponCode() {
    return this.withIdToken().get<CouponAPIResponse>('/mobility/coupons');
  }

  public sendCouponCode(couponCode: string) {
    return this.withIdToken().post<CouponAPIResponse>('/mobility/coupons', {
      coupon_code: couponCode,
    })

  }
}
