import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from '../models/login';
import { UserRepository } from '../repositories/user.repository';
import { Api } from '../services/api.service';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private loginService: LoginService,
        private userService: UserService,
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            if (Api.getToken() === null) {
                this.logout()
                return false
            }

            // If we have a token but it's not valid, we can try to refresh it
            return this.refreshToken()
                .then(() => {
                    // If we can download the user profile, we're good to go
                    return this.loginService.profile()
                        .toPromise()
                        .then(res => {
                            if (res.message.status === 'success') {
                                UserRepository.getInstance().user(res.data);
                                return true
                            } else {
                                this.logout()
                                return false
                            }
                        })
                        .catch(err => {
                            this.logout()
                            return false
                        })
                })
                .catch(err => {
                    this.logout()
                    return false
                })
    }

    private refreshToken(): Promise<void> {
        // We will not refresh the token unless it's been at least 10min since the last refresh
        const lastRefresh = sessionStorage.getItem('hb-last-refresh')
        if (lastRefresh && Date.now() - parseInt(lastRefresh) < 600000) {
            return Promise.resolve()
        }

        return new Promise((resolve, reject) => {
            this.userService.refresh_token().toPromise()
                .then(response => {
                    if (response?.message?.status !== 'success') {
                        return reject()
                    }

                    sessionStorage.setItem('hb-last-refresh', Date.now().toString())
                    localStorage.setItem('hb-access-token', response.data.access_token);
                    localStorage.setItem('hb-id-token', response.data.id_token);
                    resolve()
                })
            })
    }

    private logout() {
        this.loginService.logout()
        this.router.navigate(['/sign-in'])
    }

}
