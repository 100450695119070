import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserModel } from '../models/login';
import { RefreshTokenResponse } from '../models/user';

@Injectable({
	providedIn: 'root'
})
export class UserRepository {
	private _user?: UserModel
	public $user = new Subject<UserModel>();

	private static instance: UserRepository
	public static getInstance(): UserRepository {
		if (!UserRepository.instance) {
			UserRepository.instance = new UserRepository()
		}
		return UserRepository.instance
	}

	public user(newValue?: UserModel): UserModel {
		if (newValue) {
			this.storeUser(newValue)
		}

		return this.retrieveUser()
	}

	private storeUser(user: UserModel) {
		this._user = user
		sessionStorage.setItem('user', JSON.stringify(user))
		this.$user.next(user);
	}

	private retrieveUser(): UserModel|null {
		if (this._user) {
			return this._user
		}

		const user = sessionStorage.getItem('user')
		if (user) {
			return JSON.parse(user)
		}
		return null
	}

}
